@font-face {
  font-family: "gotham_book";
  src: url("./fonts/Gotham Book Regular.otf") format("opentype");
}

@font-face {
  font-family: "gotham_bold";
  src: url("./fonts/Gotham Black.otf") format("opentype");
}

@font-face {
  font-family: "gotham_ultra";
  src: url("./fonts/GOTHAM\ ULTRA.otf") format("opentype");
}

@font-face {
  font-family: "gotham_medium";
  src: url("./fonts/Gotham\ Medium.otf") format("opentype");
}

mark {
  background-color: rgba(243, 246, 31) !important;
  mix-blend-mode: multiply;
  padding: 0;
}

#crap-dropdown {
  font-size: 1rem;
  text-transform: uppercase !important;
  padding: 5%;
  background-color: white !important;
  border-width: 3px;
}
#input-dump {
  font-size: 7rem !important;
}

.intro-paragraph-adjust {
  width: 40%;
}

.intro-image-adjust {
  width: 50%;
}

.btn-outline-dark:hover {
  color: black !important;
}
.blend-multiply {
  mix-blend-mode: multiply;
}

.color-black {
  color: #000000;
}

.crap-round-button {
  border-radius: 11px !important;
}

.crap-input-big {
  font-size: 100pt !important;
}

.crap-input-big-m {
  font-size: 30pt !important;
}

.crap-remix-dump {
  float: right !important;
}

.crap-successful {
  color: #4bae4f;
}

.crap-fail {
  color: #f34235;
}
.extra-border {
  border: 10px !important;
}
.App {
  font-family: "gotham_bold" !important;
}

.font-regular {
  font-family: "gotham_book" !important;
}

.font-bold {
  font-family: "gotham_bold" !important;
}

.font-ultra {
  font-family: "gotham_ultra" !important;
}

.font-medium {
  font-family: "gotham_medium" !important;
}

.generator-mobile {
  font-size: 2.5em !important;
}

.text-generator {
  font-family: "gotham_bold";
  font-size: 5vw;
  word-break: keep-all !important;
}

.main-container {
  text-align: center;
  height: 100vh;
}

.big-text {
  font-size: 5.7rem;
  font-weight: 700;
  color: #000000;
}

.big-generate-text {
  font-size: 8rem;
  font-weight: 750;
  color: #000000;
  line-height: 1;
}

.btn-large-bold {
  font-weight: 700 !important;
  border-radius: 0 !important;
  font-size: 2.5rem !important;
}

.dump-header {
  top: 2rem;
}

.footer-img {
  width: 20% !important;
}

.form-control {
  font-size: 3rem !important;
  text-align: center;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

.bold-text {
  font-weight: bold;
}

.highlight-word {
  text-decoration: underline;
  cursor: pointer;
}

.statement-text {
  padding-bottom: 2rem;
}

.sub-lock {
  position: absolute;
  left: 50%;
  top: 100%;
  right: 0;
  width: 200px;
}

.sub-text {
  font-size: 0.7rem;
  text-decoration: none;
  font-weight: 500;
  position: absolute;
  left: 0;
  right: 0;
  top: 85px !important;
  margin-left: auto;
  margin-right: auto;
  width: 90px;
  /*  border: 1px solid; */
  transition: 0.5s ease;
}

.locked-text {
  color: rgb(233, 233, 233);
  background-color: grey;
}

.unlocked-text {
  color: #000000;
}

.toast-alert {
  bottom: 15px;
  left: 15px;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 20px !important;
  width: 7rem;
}

.crap-button-outlined {
  background: rgba(0, 0, 0, 0);
  border: 3px solid #000000;
  border-radius: 11px;
  padding: 5px 10px;
  color: #000000;
  display: inline-block;
  text-align: center;
}

.crap-button-outlined-lg {
  background: rgba(0, 0, 0, 0);
  border: 3px solid #000000;
  border-radius: 11px;
  padding: 5px 50px;
  color: #000000;
  display: inline-block;
  text-align: center;
  font-size: xx-large;
}

.crap-button-outlined-lg-mfont {
  background: rgba(0, 0, 0, 0);
  border: 3px solid #000000;
  border-radius: 11px;
  padding: 5px 50px;
  color: #000000;
  display: inline-block;
  text-align: center;
  font-size: x-large;
}

.crap-button-black {
  background: 000000;
  border: 3px solid #000000;
  border-radius: 11px;
  padding: 5px 10px;
  color: #000000;
  display: inline-block;
  text-align: center;
}

.crap-button-black-lg {
  background-color: #000000;
  border: 3px solid #000000;
  border-radius: 11px;
  padding: 5px 50px;
  color: white;
  display: inline-block;
  text-align: center;
  font-size: xx-large;
}

.crap-button-black-lg-mfont {
  background-color: #000000;
  border: 3px solid #000000;
  border-radius: 11px;
  padding: 5px 50px;
  color: white;
  display: inline-block;
  text-align: center;
  font-size: x-large;
}

.btn-dark {
  background: #000000 !important;
  border-radius: 11px !important;
}

.btn-outline-dark {
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 11px !important;
}

.text-input-bg {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.crap-social-icons {
  width: 10% !important;
}

/* On screens that are 992px or less */
@media screen and (max-width: 992px) {
  .big-text {
    font-size: 5rem;
  }

  .big-generate-text {
    font-size: 5rem;
  }

  .crap-button-outlined-lg {
    background: rgba(0, 0, 0, 0);
    border: 3px solid #000000;
    border-radius: 11px;
    padding: 5px 10px;
    color: #000000;
    display: inline-block;
    text-align: center;
    font-size: xx-large;
  }

  .crap-button-outlined-lg-m {
    background: rgba(0, 0, 0, 0);
    border: 3px solid #000000;
    border-radius: 11px;
    padding: 5px 50px;
    color: #000000;
    display: inline-block;
    text-align: center;
    font-size: small;
  }

  .crap-button-black-lg-m {
    background-color: #000000;
    border: 3px solid #000000;
    border-radius: 11px;
    padding: 5px 35px;
    color: white;
    display: inline-block;
    text-align: center;
    font-size: small;
  }

  .sub-text {
    font-size: 0.7rem;
    text-decoration: none;
    font-weight: 500;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    /*  border: 1px solid; */
    transition: 0.5s ease;
  }
}

/* On screens that are 600px or less */
@media screen and (max-width: 600px) {
  .big-text {
    font-size: 2rem;
    line-height: 4rem;
  }

  .big-generate-text {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .btn-large-bold {
    font-size: 1.5rem !important;
  }

  .toast-alert {
    bottom: 15px;
    left: 0;
  }

  .form-control {
    font-size: 2rem !important;
  }

  .sub-text {
    line-height: 1rem;
    top: 45px;
    width: 70px;
  }

  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    width: auto;
  }

  .footer-img {
    width: 60% !important;
  }
  .crap-button-outlined-lg {
    background: rgba(0, 0, 0, 0);
    border: 3px solid #000000;
    border-radius: 11px;
    padding: 5px 35px;
    color: #000000;
    display: inline-block;
    text-align: center;
    font-size: xx-large;
  }

  .crap-button-black-lg {
    background-color: #000000;
    border: 3px solid #000000;
    border-radius: 11px;
    padding: 5px 50px;
    color: white;
    display: inline-block;
    text-align: center;
    font-size: xx-large;
  }

  .sub-text {
    font-size: 0.7rem;
    text-decoration: none;
    font-weight: 500;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px !important;
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    /*  border: 1px solid; */
    transition: 0.5s ease;
  }
}
